<template>
    <div  style="padding-top:20px">
      <apexchart
        type="radialBar"
        :options="chartOptions"
        :series="series"
        height="100px"
      ></apexchart>
      {{this.title}}
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "small-goal-status",
  props: {
    data: Object,
    title: String,
  },
  data() {
    return {
      series: [this.data],

      chartOptions:{

          chart: {
              type: 'radialBar',
              offsetY: -20,
              sparkline: {
                enabled: true
            }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#ffffff",
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: 'transparent',
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: '12px'
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        colors: ['#68BC00'],
        fill: {
          type: 'solid',
          color: '#68BC00'
        },
        labels: [''],
        },

    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {},
};
</script>
