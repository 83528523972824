<template>
  <div>
    <b-card>
      <h1>{{ getName }}</h1>

      <div class="row mb-4" v-for="(getFrameworkHeader, index) in getFrameworkHeaders" :key="index">
        <div class="col-12" :ref="getFrameworkHeader.category_code" :id="getFrameworkHeader.category_code">
            <b-button block  @click="showTab(index)" variant="info">
              <b-row>
                <b-col>
                  <span class="floatTextLeft">
                    {{getFrameworkHeader.category_code + '-' + getFrameworkHeader.function}}
                  </span>
                </b-col>
                <b-col cols="2">
                   <small-goal-status title="Current" :data="getItemsGrpahFrameworkValue('current',getFrameworkHeader.category_code)" />
                </b-col>                
                <b-col cols="2">
                   <small-goal-status title="Goal" :data="getItemsGrpahFrameworkValue('goal',getFrameworkHeader.category_code)" />
                </b-col>
              </b-row>
            </b-button>
            <b-collapse v-model="getFrameworkHeader.show" id="collapse-1" class="mt-2">
          <b-card-body>
          <b-card-title>{{ getFrameworkHeader.function }}</b-card-title>
          <b-card-text>
            <b-table
              striped
              hover
              sort-icon-left              
              :items="
                getFrameworkRequirements(getFrameworkHeader.function_code)
              "
              :fields="requirementFields"
              class="workplanindex"
              @row-clicked="rowClickHandler"
            >
              <template #cell(calc_category)="data">
                {{ getFrameworkCategory(data.item.id) }} 
              </template>
              <template #cell(goal)="data">
                {{getItemFrameworkValueValOnly('goal',data.item.objective_code)}} %
              </template>
              <template #cell(current)="data">
                {{getItemFrameworkValueValOnly('current',data.item.objective_code)}} %
              </template>
            </b-table>
          </b-card-text>
          </b-card-body>
            </b-collapse>
        </div>
      </div>

    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import { GET_FRAMEWORK,GET_DASHBOARD_STATS_PERC } from "@/core/services/store/framework.module";
import { GET_USERS } from "@/core/services/store/users.module";
import SmallGoalStatus from "@/view/content/dashboard/SmallGoalStatus.vue";

export default {
  name: "workplans",
  components: {
    SmallGoalStatus
  },
  data() {
    return {
      requirementFields: [
        { key: "objective_code", sortable: true },
        { key: "objective", sortable: true },
        { key: "current", sortable: false },        
        { key: "goal", sortable: false },

      ],
      getFrameworkHeaders: [],
      graphData: [90],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Work Plans" }]);
    this.getData();
  },
  methods: {
    getData() {
      console.log('this.currentFramework');

      var frameworkID = '';
      if (this.currentFramework.framework) {
        frameworkID = this.currentFramework.framework.id;
      }

      this.$store.dispatch(GET_FRAMEWORK,frameworkID);
      this.$store.dispatch(GET_DASHBOARD_STATS_PERC,frameworkID);
      this.$store.dispatch(GET_USERS);    
      
    },

    getItemFrameworkValueValOnly(type,code){

      var statValues = this.currentFrameworkStatsPerc.itemLevelCount.filter(function(o) {return o.objective_code == code;});
      if (statValues.length > 0) {
        if (type=='goal') {
          return statValues[0].goal;
        }
        if (type=='current') {
          return statValues[0].status;
        }

      }

      return 0;

    },


    getItemsGrpahFrameworkValue(type,code){


      var statValues = this.currentFrameworkStatsPerc.topLevelCount.filter(function(o) {return o.category_code == code;});

      if (statValues.length > 0) {
        if (type=='goal') {
          return statValues[0].goal;
        }
        if (type=='current') {
          return statValues[0].status;
        }

      }      


      return 0;

    },
    showTab(index) {
      this.getFrameworkHeaders.forEach(getFrameworkHeader => {
        getFrameworkHeader.show = false;
      });
      this.getFrameworkHeaders[index].show=!this.getFrameworkHeaders[index].show;
      window.location.href = '/#' + this.$router.currentRoute.path + '?show=' + this.getFrameworkHeaders[index].category_code;
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */

    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },

    getFrameworkRequirements(code) {
      var results = [];
      if (this.currentFramework.framework_items) {
        this.currentFramework.framework_items.forEach((framework_item) => {
          if (framework_item.function_code == code) {
            var targetListResult = results.filter(function(o) {
              return o.id == framework_item.id;
            });
            if (targetListResult.length === 0) {
              results.push(framework_item);
            }
          }
        });
      }
      return results;
    },

    getFrameworkCategory(id) {
      var results = "n/a";
      if (this.currentFramework.framework_items) {
        var targetListResult = this.currentFramework.framework_items.filter(
          function(o) {
            return o.id == id;
          }
        );
        if (targetListResult.length > 0) {
          var code = targetListResult[0].function_code;
          var name = "";

          if (targetListResult[0].show_category) {
            code = code + "-" + targetListResult[0].category_code;
            name = name + targetListResult[0].category;
          }

          if (targetListResult[0].show_sub_category) {
            code = code + "-" + targetListResult[0].sub_category_code;
            if (name != "") {
              name = name + " - ";
            }
            name = name + targetListResult[0].sub_category;
          }

          results = code + ":" + name;
        }
      }
      return results;
    },
    rowClickHandler(record, index) {
      console.log(record, index); // This will be the item data for the row
      this.$router.push({ name: "workplanmanage", params: { id: record.id } });
    },
    getFrameworkData() {
      var results = [];
      if (this.currentFramework.framework_items) {
        this.currentFramework.framework_items.forEach((framework_item) => {
          var targetListResult = results.filter(function(o) {
            return o.function_code == framework_item.function_code;
          });
          if (targetListResult.length === 0) {

            var showIt = false;
            if ( this.$route.query.show ) {
              if ( this.$route.query.show == framework_item.category_code) { showIt = true}
            }
            

            results.push({
              function_code: framework_item.function_code,
              function: framework_item.function,
              category_code: framework_item.category_code,
              category_no: parseInt(framework_item.category_code.replace( /^\D+/g, '')),
              show: showIt
            });

          }
        });
      }

      results = results.sort((a, b) => (a.category_no > b.category_no) ? 1 : -1);
      return results;
    },
    scrollTo(name) {
      if (name) {
        document.getElementById(name).scrollIntoView();
      }
      
    },    
  },
  computed: {
    ...mapGetters(["currentFramework","currentFrameworkStatsPerc"]),
    getName() {
      var result = "";
      if (this.currentFramework.framework) {
        result = this.currentFramework.framework.name;
      }
      return result;
    },
    getSortedStatuses() {
        const tempSort = this.currentFramework.statuses;
        return tempSort.sort((a, b) => (a.score < b.score) ? 1 : -1);
    }
  },
  watch: {
    currentFramework: function(newVal, oldVal) {
      // watch it
      console.log(newVal, oldVal)
      this.getFrameworkHeaders = this.getFrameworkData();      

      setTimeout(() => {
        // Remove page loader after some time
        this.scrollTo(this.$route.query.show)
      }, 200);      
    }, 
  },  
};
</script>

<style>
  .workplanindex tr {
    cursor:pointer;
  }
  .floatTextLeft {
    float: left;
    font-size: 1.5em;
  }
</style>

<style>
.table-hover tr td {
    cursor: pointer;
}
</style>